var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-tabelas",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":[
      {
        text: 'Diretório',
        value: 'directory'
      },
      {
        text: 'Espaço em disco',
        value: 'total_size'
      }
    ],"items":_vm.data,"search":_vm.search,"headerProps":{
      sortByText: 'Ordenar Por'
    },"footer-props":{
      itemsPerPageText: 'Itens por Página'
    },"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.total_size",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bytesToSize")(item.total_size))+" ")]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getRelatorio}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar os dados")])])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }