import APIService from './APIService';

export default {
  getPhpConfig() {
    return APIService.apiCall().get('server-config/php');
  },
  getServerDiskStatus() {
    return APIService.apiCall().get('server-config/disk-status');
  },
  getBackendApplicationHdUsage() {
    return APIService.apiCall().get('server-config/backend-application-hd-usage');
  }
};
