<template>
  <div>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mt-4 break-search bs-tabelas"
      label="Procurar"
      hide-details
      single-line
      style="max-width: 250px"
    />
    <v-divider class="mt-10" />
    <v-data-table
      :headers="[
        {
          text: 'Diretório',
          value: 'directory'
        },
        {
          text: 'Espaço em disco',
          value: 'total_size'
        }
      ]"
      :items="data"
      :search.sync="search"
      :headerProps="{
        sortByText: 'Ordenar Por'
      }"
      :footer-props="{
        itemsPerPageText: 'Itens por Página'
      }"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      no-results-text="Nenhum registro correspondente encontrado"
    >
      <template v-slot:[`item.total_size`]="{ item }">
        {{ item.total_size | bytesToSize }}
      </template>
      <template v-slot:[`footer.page-text`]>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="getRelatorio"
            >
              mdi-refresh
            </v-icon>
          </template>
          <span>Clique aqui para recarregar os dados</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ServerConfigService from '@/services/ServerConfigService';

export default {
  name: 'UsoHdAplicacaoBackend',
  data() {
    return {
      data: [],
      search: '',
      loading: false
    };
  },
  mounted() {
    this.getRelatorio();
  },
  methods: {
    getRelatorio() {
      this.data = [];
      this.loading = true;
      ServerConfigService.getBackendApplicationHdUsage()
        .then(({ data }) => {
          this.data = data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error(
            'Erro ao recuperar o espaço em disco ocupado pelos diretórios da aplicação backend.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
}
</script>
