<template>
  <v-container fluid>
    <highcharts
      :options="chartOptions"
      style="height: 300px !important"
    />
  </v-container>
</template>
  
<script>
import Highcharts from 'highcharts';
import ServerConfigService from '@/services/ServerConfigService';

export default {
  name: 'UsoHdServidorWeb',
  mounted() {
    this.getStatusDiscoServidor();
  },
  data: () => ({ 
    diskTotalSpace: 0,
    diskUsedSpace: 0 
  }),
  computed: {
    diskTotalSpaceGigabytes() {
      return Math.ceil(this.diskTotalSpace / 1024 / 1024 / 1024);
    },
    diskUsedSpaceGigabytes() {
      return Math.ceil(this.diskUsedSpace / 1024 / 1024 / 1024);
    },
    gaugeOptions() {
      return {
        chart: {
          type: 'solidgauge',
          style: {
            fontFamily: 'muli'
          }
        },
        title: null,
        pane: {
          center: ['50%', '85%'],
          size: '140%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#fafafa',
            borderRadius: 5,
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },
        tooltip: {
          enabled: false
        },
        yAxis: {
          stops: [
            [0.6, '#55BF3B'], // green
            [0.7, '#DDDF0D'], // yellow
            [0.8, '#DF5353']  // red
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70
          },
          labels: {
            y: 16
          }
        },
        plotOptions: {
          solidgauge: {
            borderRadius: 3,
            dataLabels: {
              y: 5,
              borderWidth: 0
            }
          }
        }
    };
    },
    chartOptions() {
      return Highcharts.merge(this.gaugeOptions, {
        yAxis: {
          min: 0,
          max: this.diskTotalSpaceGigabytes,
          tickPositioner: function() {
            return [this.min, this.max];
          },
          title: {
            text: 'Uso de HD'
          }
        },
        credits: {
            enabled: false
        },
        series: [{
          name: 'Uso de HD',
          data: [
            this.diskUsedSpaceGigabytes
          ],
          dataLabels: {
            format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">GB</span>' +
            '</div>'
          },
          tooltip: {
            valueSuffix: ' GB'
          }
        }]
      });
    }
  },
  methods: {
    getStatusDiscoServidor() {
      ServerConfigService.getServerDiskStatus()
        .then(({ data }) => {
          const {
            diskTotalSpace,
            diskUsedSpace
          } = data;
          this.diskTotalSpace = diskTotalSpace;
          this.diskUsedSpace = diskUsedSpace;
        })
        .catch((error) => console.log('Error: ' + error));
    }
  }
};
</script>