<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-harddisk"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Tabelas x Disco
            </div>
          </template>
          <v-row>
            <v-col cols="12">
              <v-card tile>
                <v-subheader>
                  Uso de Disco do Servidor Web
                </v-subheader>
                <v-divider />
                <v-card-text>
                  <uso-hd-servidor-web />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card tile>
                <v-subheader>
                  Uso de Disco do Banco de Dados por tabela
                </v-subheader>
                <v-divider />
                <v-card-text>
                  <uso-hd-banco-dados-por-tabela />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card tile>
                <v-subheader>
                  Uso de Disco da Aplicação Backend por diretório 
                </v-subheader>
                <v-divider />
                <v-card-text>
                  <uso-hd-aplicacao-backend />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsoHdServidorWeb from '@/components/general/graficos/UsoHdServidorWeb';
import UsoHdBancoDadosPorTabela from '@/components/general/data-tables/UsoHdBancoDadosPorTabela';
import UsoHdAplicacaoBackend from '@/components/general/data-tables/UsoHdAplicacaoBackend';

export default {
  name: 'TabelasXDisco',
  components: {
    UsoHdServidorWeb,
    UsoHdBancoDadosPorTabela,
    UsoHdAplicacaoBackend
  }
};
</script>
